import React, { useContext, useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { DataContext } from './DataContext';
import { format, parseISO } from 'date-fns';

// Import and register Chart.js components
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function OutOfServiceBarChart() {
  const { data } = useContext(DataContext);
  const [labels, setLabels] = useState([]);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (data.length > 0) {
      // Filter data to only include rows with an out_of_service_date
      const filteredData = data.filter(row => row.out_of_service_date);
        
      // Group by month/year and count occurrences
      const monthYearCounts = filteredData.reduce((acc, row) => {
        try {
          const parsedDate = parseISO(row.out_of_service_date);
          const monthYear = format(parsedDate, 'yyyy-MM'); // Format as '2024-08'
          if (!acc[monthYear]) {
            acc[monthYear] = 0;
          }
          acc[monthYear]++;
        } catch (error) {
          console.error('Error parsing date:', row.out_of_service_date, error);
        }
        return acc;
      }, {});

      // Extract labels and data from the grouped data
      const labels = Object.keys(monthYearCounts).sort();
      const counts = labels.map(label => monthYearCounts[label]);

      setLabels(labels);
      setChartData(counts);

      console.log("Labels", labels);
      console.log("Counts", counts);
    }
  }, [data]);

  const chartConfig = {
    labels,
    datasets: [
      {
        label: 'Companies Out of Service',
        data: chartData,
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div>
      <h3>Companies Out of Service Per Month</h3>
      <Bar data={chartConfig} options={options} />
    </div>
  );
}
