import React, { useState, useContext } from 'react';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';
import TableRenderers from 'react-pivottable/TableRenderers';
import Plotly from 'plotly.js-dist';
import createPlotlyComponent from 'react-plotly.js/factory';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';
import { DataContext } from "./DataContext";

// Create Plotly React component via dependency injection
const Plot = createPlotlyComponent(Plotly);

// Create Plotly renderers via dependency injection
const PlotlyRenderers = createPlotlyRenderers(Plot);

export default function PivotView() {
  const [state, setState] = useState({});
  const { data, columns } = useContext(DataContext);


  return (
    <div>
      <PivotTableUI
        data={data}
        onChange={s => setState(s)}
        renderers={{ ...TableRenderers, ...PlotlyRenderers }}
        {...state}
      />
    </div>
  );
}
