import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import './App.css'; // Ensure this import points to your CSS file
import { DataContext } from "./DataContext";
import { useContext } from "react";
import OutOfServiceBarChart from "./ChartComponent";

const muiCache = createCache({
  key: "mui-datatables",
  prepend: true
});

export default function TableView() {

    const { data, columns, processing } = useContext(DataContext);
    console.log("Data", data);

  const options = {
    filterType: 'checkbox',
    pagination: true,
    rowsPerPage: 15,
    onTableChange: (action, tableState) => {
    }
  };

  if (data.length == 0) {
    return (
        <div style={{width: '100vw', height:'90vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div class="loader"></div>
        </div>
        );
  } else
  return (
    <>
        <CacheProvider value={muiCache}>
        <ThemeProvider theme={createTheme()}>
            <MUIDataTable
            title={"FMSCA Records"}
            data={data}
            columns={columns}
            options={options}
            />
            {processing && <p>Loading more data...</p>}
            <OutOfServiceBarChart />
        </ThemeProvider>
        </CacheProvider>
    </>
  );
}
