import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import TableView from "./TableView";
import PivotView from "./PivotView";
import { DataProvider } from "./DataContext";

export default function App() {
  return (
    <DataProvider>
      <Router>
        <div style={{ padding: "10px", textAlign: "center" }}>
          {/* Navigation Links */}
          <Link to="/" style={{ margin: "10px" }}>Table View</Link>
          <Link to="/pivot" style={{ margin: "10px" }}>Pivot View</Link>
        </div>
        <Routes>
          <Route path="/" element={<TableView />} />
          <Route path="/pivot" element={<PivotView />} />
        </Routes>
      </Router>
    </DataProvider>
  );
}
