import React, { createContext, useState, useEffect } from "react";
import * as XLSX from "xlsx";

export const DataContext = createContext();

export function DataProvider({ children }) {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [processing, setProcessing] = useState(true);

  const fetchDataInBatches = async (batchSize = 200, delay = 5000) => {
    try {
      const response = await fetch('/data.xlsx');
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }
      const arrayBuffer = await response.arrayBuffer();
      const workbook = XLSX.read(arrayBuffer, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const rows = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      console.log("Rows", rows);
      const headers = rows[0] || [];
      const filteredHeaders = headers.map(header => ({
        name: header,
        label: header.replace(/_/g, ' '),
        options: {
          filter: true,
          sort: true,
        }
      }));

      setColumns(filteredHeaders);

      // Function to process a single batch
      const processBatch = (batchStart) => {
        const batchEnd = Math.min(batchStart + batchSize, rows.length - 1);
        const newBatch = rows.slice(batchStart + 1, batchEnd + 1).map(row =>
          headers.reduce((acc, header, index) => {
            acc[header] = row[index] || '';
            return acc;
          }, {})
        );

        setData(prevData => [...prevData, ...newBatch]);

        if (batchEnd < rows.length - 1) {
          // Continue processing the next batch after a short delay
          setTimeout(() => processBatch(batchEnd), delay);
        } else {
          setProcessing(false); // Finished processing all batches
        }
      };

      // Start processing from the first batch
      processBatch(0);
    } catch (error) {
      console.error('Error fetching or processing the Excel file:', error);
      setProcessing(false);
    }
  };

  useEffect(() => {
    fetchDataInBatches(); // Call the function to start fetching data in batches
  }, []);

  return (
    <DataContext.Provider value={{ data, columns, processing }}>
      {children}
    </DataContext.Provider>
  );
}
